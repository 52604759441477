<template>
  <NeoDrawerLayout :showFooter="true">
    <div class="AddManager">
      <a-form-model v-bind="layout" ref="managerForm" :model="formdata" :rules="rules" :colon="false">
        <!-- 姓名 -->
        <a-form-model-item class="mb-3" prop="name" :label="$t('managerInfo.name')">
          <a-input v-model="formdata.name" :placeholder="$t('managerInfo.name_holder')"></a-input>
        </a-form-model-item>
        <!-- 邮箱 -->
        <a-form-model-item class="mb-1" prop="email" :label="$t('managerInfo.email')">
          <a-input v-model="formdata.email" :placeholder="$t('managerInfo.email_holder')" :disabled="edit"></a-input>
          <NeoTips :label="$t('managerInfo.tip_label')" :text="$t('managerInfo.tip_text')" class="tips" />
        </a-form-model-item>
        <!-- 手机 -->
        <a-form-model-item class="mb-3" prop="phone" :label="$t('managerInfo.phone_number')">
          <a-input v-model="formdata.phone" :placeholder="$t('managerInfo.phone_number_holder')"></a-input>
        </a-form-model-item>
        <!-- 部门 -->
        <a-form-model-item class="mb-3" prop="department" :label="$t('managerInfo.department')">
          <a-input v-model="formdata.department" :placeholder="$t('managerInfo.department_holder')"></a-input>
        </a-form-model-item>
        <a-form-model-item class="mb-3" :label="$t('managerInfo.permission')" prop="seniorManager">
          <a-select v-model="formdata.type">
            <a-select-option v-for="item in managerList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <template v-slot:footer>
      <a-space :size="10">
        <a-button v-if="edit" type="primary" @click="onEditSubmit" :loading="loading">{{ $t('submit') }}</a-button>
        <a-button v-else type="primary" @click="onSubmit" :loading="loading">{{ $t('submit') }}</a-button>
      </a-space>
    </template>
  </NeoDrawerLayout>
</template>

<script>
import NeoTips from '@/components/common/NeoTips'
const { validate } = window.$g

export default {
  name: 'AddManager',
  components: { NeoTips },
  props: ['id', 'edit', 'managerInfo'],
  data() {
    return {
      loading: false,
      layout: {
        labelCol: { flex: '60px' },
        wrapperCol: { flex: 'auto' },
      },
      formdata: {
        name: '',
        email: '',
        phone: '',
        department: '',
        type: 1,
      },
      rules: {
        name: [validate.required, validate.only_zh_en_num],
        department: [validate.only_zh_en_num],
        phone: [validate.only_num],
        email: [validate.email, validate.required],
        type: [validate.required],
      },
      default: '',
      managerList: [
        {
          value: 1,
          label: this.$t('orderInfo.manager'),
        },
      ],
    }
  },
  mounted() {
    if (this.edit) {
      this.getManager()
    }
  },
  watch: {
    'formdata.name': {
      deep: true,
      handler(v) {
        this.formdata.name = this.$g.limitStr(v, 10)
      },
    },
    'formdata.email': {
      deep: true,
      handler(v) {
        this.formdata.email = this.$g.limitStr(v, 255)
      },
    },
    'formdata.phone': {
      deep: true,
      handler(v) {
        this.formdata.phone = this.$g.limitStr(v, 11)
      },
    },
    'formdata.department': {
      immediate: true,
      deep: true,
      handler(v) {
        this.formdata.department = this.$g.limitStr(v, 20)
      },
    },
  },
  computed: {
    gender() {
      return this.$store.getters['app/getDictByType']('GENDER')
    },
  },
  methods: {
    onSubmit() {
      this.$refs.managerForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('saveSupEmployeeDetail', {
              ...this.formdata,
            })
            this.loading = false
            this.$message.success(this.$t('prompt.create_success'))
            this.$emit('show')
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    onEditSubmit() {
      this.$refs.managerForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            const { id, name, email, phone, department, type } = this.formdata
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('updateDeliverManager', {
              id,
              name,
              email,
              phone,
              department,
              type,
            })
            this.loading = false
            this.$message.success(this.$t('prompt.edit_success'))
            this.$emit('show')
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    async getManager() {
      try {
        const data = await this.$http({ key: 'supEmployeeDetailId', params: { id: this.managerInfo.id } })
        this.formdata = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.AddManager {
  /deep/.ant-form-item {
    display: flex;
  }
}
</style>
